<template>
    <div>
        <b-card no-body class="shadow-none settings-collapse" v-for="(value, key, index) in permissionSet.entries" :key="index">
            <a v-b-toggle="key" class="text-dark" href="javascript:void(0);">
                <b-card-header header-tag="header">
                    <h4 class="m-0 d-inline-flex">
                        {{ $t(`roles.permissions.${key}.title`) }}
                    </h4>
                    <div class="d-inline-flex float-right">
                        <div class="btn btn-sm btn-dark">
                            {{ $t('roles.permissions.expand') }}
                        </div>
                    </div>
                </b-card-header>
            </a>
            <b-collapse :id="key" accordion="permissions">
                <b-card-body>
                    <b-card-text>
                        <div v-for="(e, index) in value" :key="index">
                            <div class="row mb-2">
                                <template v-if="e.type === 'permission'" >
                                    <div class="w-75">
                                        <div>
                                            <h5 class="mb-0">
                                                {{ $t(`roles.permissions.${e.key}.title`) }}
                                            </h5>
                                            <span>
                                            {{ $t(`roles.permissions.${e.key}.description`) }}
                                        </span>
                                        </div>
                                    </div>
                                    <div class="w-25">
                                        <div class="text-center">
                                            <toggle-button v-model="e.value" :sync="true"
                                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                                           :height="30"
                                                           :width="70"
                                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                                           style="font-size: 13px !important;"
                                                           class="m-0 mt-1 mb-1">
                                            </toggle-button>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="e.type === 'component'">
                                    <component :is="e.value"></component>
                                </template>
                            </div>
                        </div>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
export default {
    props: ['permissionSet']
}
</script>

<style scoped>
.settings-collapse {
    margin-bottom: 1rem;
}
</style>