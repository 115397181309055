// utility file to ensure order of permissions

import IntegrationDisclaimer from "@/components/lazy/permissions/components/IntegrationDisclaimer";
import SupportAuthorizationDisclaimer from "@/components/lazy/permissions/components/SupportAuthorizationDisclaimer";


const permissions = {
    organization: [
        'organization.view',
        'organization.modify',
        'organization.teams.add',
        'organization.teams.remove',
        //'organization.teams.modify',
        'organization.members.list',
        'organization.members.add',
        'organization.members.remove',
        'organization.members.modify',
        'organization.roles.list',
        'organization.roles.create',
        'organization.roles.delete',
        'organization.roles.modify'
    ],
    team: [
        'team.view',
        'team.modify',
        'team.members.list',
        'team.members.add',
        'team.members.modify',
        'team.members.remove',
        'team.roles.list',
        'team.roles.create',
        'team.roles.modify',
        'team.roles.delete',
        'team.roles.hierarchy-change'
    ],
    server: [
        'server.views.info',
        'server.views.dashboard',
        'server.views.feed',
        'server.views.pi',
        'server.views.audit-log',
        'server.views.rcon-log',
        'server.views.server-log',
        'server.settings.modify',
        'server.configuration.modify',
        'server.stats.reset-player',
        'server.game.shutdown',
        'server.game.kick',
        'server.game.lock',
        'server.game.rcon',
        'server.game.message.server',
        'server.game.message.private',
        'server.whitelist.modify',
        'server.whitelist.view',
        'server.whitelist.add',
        'server.whitelist.remove',
        'server.queuepriority.view',
        'server.queuepriority.add',
        'server.queuepriority.remove',
        'server.notes.view',
        'server.notes.edit',
        'server.notes.remove',
        'server.reserved-slots.add',
        'server.reserved-slots.remove',
        'server.filter.logs',
        'server.filter.feed',
        'server.integrations.modify',
        [
            IntegrationDisclaimer,
            'game-integration.map',
            // 'game-integration.character',
            'game-integration.teleport',
            'game-integration.kill',
            'game-integration.heal',
            'game-integration.item-spawn',
            // 'game-integration.filter.log',
            // 'game-integration.filter.feed',
            'game-integration.actions'
        ]
    ],
    banlist: [
        'banlist.modify',
        'banlist.bans.add-temporary',
        'banlist.bans.add-permanent',
        'banlist.bans.remove-own',
        'banlist.bans.remove',
        'banlist.list',
        'banlist.view',
        'banlist.protocol',
        'banlist.integrations.modify'
    ],
    org: [
        ['org.authorization', SupportAuthorizationDisclaimer],
        'org.reset-game-stats',
        'org.reset-stats',
        'org.reset-database'
    ]
}

const { organization, ..._teamPermissions } = permissions
export const organizationPermissions = permissions
export const teamPermissions = _teamPermissions

const togglePermissions = (permissions) => {
    function togglePermissions(permissions, _value) {
        let affected = 0
        Object.entries(permissions).forEach(([key, value]) => {
            if (key !== 'org') {
                if (typeof value === 'object') {
                    affected += togglePermissions(value, _value)
                } else {
                    if (value !== _value) {
                        affected++
                    }
                    permissions[key] = _value
                }
            }
        })
        return affected
    }

    if (!togglePermissions(permissions, true)) {
        togglePermissions(permissions, false)
    }
}

export const generatePermissionSet = (permissionEntries, permissions) => {
    if (!permissions) {
        return permissions
    }
    // eslint-disable-next-line no-undef
    permissions = structuredClone(permissions)
    const result = {}

    function applyEntry(entry) {
        const parts = entry.split('.')
        let permission = permissions
        let part
        while ((part = parts.shift())) {
            if (typeof permission[part] === 'boolean') {
                break
            }
            permission = permission[part]
            if (permission === undefined) {
                return
            }
        }
        const child = {
            type: 'permission',
            key: entry
        }
        Object.defineProperty(child, 'value', {
            get: () => permission[part],
            set: (value) => permission[part] = value
        })
        return child
    }

    for (const [key, value] of Object.entries(permissionEntries)) {
        const chunk = []
        for (const entry of value) {
            if (typeof entry === 'string') {
                const permission = applyEntry(entry)
                if (permission) {
                    chunk.push(permission)
                }
            } else if (Array.isArray(entry)) {
                let empty = true
                const items = []
                for (const item of entry) {
                    if (typeof item === 'string') {
                        const permission = applyEntry(item)
                        if (permission) {
                            items.push(permission)
                            empty = false
                        }
                    } else {
                        items.push({
                            type: 'component',
                            value: item
                        })
                    }
                }
                if (!empty) {
                    chunk.push(...items)
                }
            }
        }
        if (chunk.length > 0) {
            result[key] = chunk
        }
    }
    return { entries: result, _permissions: permissions, toggle: () => togglePermissions(permissions) }
}