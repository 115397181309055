<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .card-body.disabled {
    opacity: 0.65;
    cursor: not-allowed !important;
  }
  .card-body.draggable {
    cursor: move;
  }
  .card-body.not-draggable {
    cursor: pointer;
  }
  .c-dot {
    height: 1.3rem;
    width: 1.3rem;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
  }
</style>
<script>
import draggable from 'vuedraggable'
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

import { teamPermissions, generatePermissionSet, organizationPermissions } from "../../../permissions/permissions.js"
import PermissionCollapse from "../../../permissions/PermissionCollapse.vue";

export default {
  props: ['team_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect,
    draggable,
    PermissionCollapse
  },
  validations: {
    featuredRole: {
      color: {required},
      name: {required, minLength: minLength(4), maxLength: maxLength(16)}
    }
  },
  computed: {
    ...mapGetters([
      'getTeams'
    ]),
    isModified() {
      let a = this.originalRoles; let b = this.roles;
      if (a === b) return false;
      if (a == null || b == null) return true;
      if (a.length !== b.length) return true;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return true;
      }
      return false;
    }
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getRoles: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/roles`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.permissions = data.permissions;
          this.originalRoles = data.roles.sort((a, b) => a.hierarchy < b.hierarchy ? -1 : 1);
          this.roles = data.roles.sort((a, b) => a.hierarchy < b.hierarchy ? -1 : 1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async onRoleClick(role_id) {
      this.roles.forEach((role) => {
        if(role.id === role_id) {this.featuredRole = role; return;}
      });
      if(!this.featuredRole.modify && !this.featuredRole.delete) return;
      this.featuredRoleName = this.featuredRole.name;
      if(!this.featuredRole.modify) return;
      this.loadingFeaturedRolePermissions = true;
      this.featuredRolePermissionsError = false;
      this.$refs['featuredRole'].show();

      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/permissions`);
        url.searchParams.append('role_id', this.featuredRole.id);

        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });

        if(response.ok) {
          let data = await response.json();
          this.featuredRolePermissions = data.permissions;
          this.featuredRolePermissionSet = generatePermissionSet(teamPermissions, data.permissions);
					if(!this.featuredRole.ownership_role) {
						// TODO: @philippj Fix this in UAC
						if (!this.featuredRolePermissions.server.integrations || this.featuredRolePermissions.server.integrations === null) {
							this.featuredRolePermissions.server.integrations = {
								modify: false
							};
						}
						if (!this.featuredRolePermissions.banlist.integrations || this.featuredRolePermissions.banlist.integrations === null) {
							this.featuredRolePermissions.banlist.integrations = {
								modify: false
							};
						}
					}
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        this.featuredRolePermissionsError = true;
        console.log(`[ERROR] ${error}`);
      }

      this.$nextTick(() => {
        this.loadingFeaturedRolePermissions = false;
      });
    },
    async validateRoleEdit() {
      this.$v.featuredRole.$touch();
    },
    async updateFeaturedRole() {
      this.$v.featuredRole.$touch();
      if(this.$v.featuredRole.$error) return;
      this.featuredRoleInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          role_id: this.featuredRole.id,
          permissions: this.featuredRolePermissionSet._permissions,
          name: this.featuredRole.name,
          color: this.featuredRole.color
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/role`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('roles.edit.success')
          });
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      }  catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.featuredRoleInProgress = false;
    },
    async deleteRole() {

      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('roles.edit.delete.confirm.text'),
        title: this.$t('roles.edit.delete.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('roles.edit.delete.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;
      this.featuredRoleInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          role_id: this.featuredRole.id
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/role-delete`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('roles.edit.delete.success')
          });
          this.$refs['featuredRole'].hide();
          await this.getRoles();
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      }  catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.featuredRoleInProgress = false;
    },
    async updateHierarchy() {
      if(!this.isModified) return;
      this.hierarchyChangeInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));

      let hierarchy = {};
      let index = 0;
      this.roles.forEach((role) => {
        hierarchy[role.id] = index;
        index++;
      });

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          hierarchy: hierarchy
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/role-hierarchy`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          await this.getRoles();
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      }  catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.hierarchyChangeInProgress = false;
    },
    async createNewRole() {
      this.newRoleInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/role-create`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('roles.new.success')
          });
          await this.getRoles();
        } else {
          if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      }  catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.newRoleInProgress = false;
    }
  },
  mounted() {
    this.getRoles();
  },
  data() {
    return {
      ready: false,
      error: false,
      inProgress: false,
      permissions: {},
      originalRoles: [],
      roles: [],
      hierarchyChangeInProgress: false,
      loadingFeaturedRolePermissions: false,
      featuredRolePermissionsError: false,
      featuredRoleInProgress: false,
      featuredRole: {
        name: null
      },
      featuredRoleName: null,
      featuredRolePermissions: null,
      featuredRolePermissionSet: null,
      newRoleInProgress: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-9 col-sm-12">
          <div>
            <draggable draggable=".draggable" v-model="roles" group="people" @start="drag=true" @end="drag=false">
              <div
                  v-for="role in roles"
                  :key="role.id"
                  class="card card-body hard-border"
                  :class="{
                    'draggable': role['hierarchy-change'],
                    'not-draggable': !role['hierarchy-change'],
                    'disabled': !role.modify,
                  }"
                  v-on:click="onRoleClick(role.id)"
              >
                <i class="fas fa-sort float-left mt-2 position-absolute" v-b-tooltip.hover :title="$t('teams.view.roles.hierarchy')" v-if="role['hierarchy-change']"/>

                <div class="row">
                  <div class="col-1 text-center">
                    <i class="far fa-shield-alt font-size-24" :style="{'color': role.color}" />
                  </div>
                  <div class="col">
                    <i class="fas fa-lock-alt text-muted small align-middle mb-2" v-b-tooltip.hover :title="$t('teams.view.roles.locked')" v-if="!role.modify" />
                    <h3 class="mb-0 d-inline">
                      {{role.name}}
                    </h3>
                    <i class="fad fa-crown small text-white align-middle mb-2" v-if="role.ownership_role" />
                    <h4 class="ml-2 mb-0 text-muted d-inline">
                      {{role.uuid}}
                    </h4>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12">
          <div class="row">
            <div class="col-lg-12 col-sm-12 mb-2" v-if="isModified">
              <button class="btn btn-success btn-block" v-on:click="updateHierarchy()" :class="{'disabled': hierarchyChangeInProgress}">
                <half-circle-spinner
                    v-if="hierarchyChangeInProgress"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                <i class="far fa-save" v-if="!hierarchyChangeInProgress"/>
                {{ $t('roles.edit.hierarchy.button') }}
              </button>
            </div>
            <div class="col-lg-12 col-sm-12" v-if="permissions.create">
              <button class="btn btn-primary btn-block" v-on:click="createNewRole()" :class="{'disabled': newRoleInProgress}">
                <half-circle-spinner
                    v-if="newRoleInProgress"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                <i class="far fa-plus" v-if="!newRoleInProgress"/>
                {{ $t('roles.new.button') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal
          ref="featuredRole"
          size="lg"
          :title="$t('roles.edit.title', { role: featuredRoleName })"
          title-class="font-18"
          hide-footer
          scrollable
      >
        <template v-if="loadingFeaturedRolePermissions">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
            </div>
          </div>
        </template>
        <template v-else-if="featuredRolePermissionsError">
          <div class="row mt-4">
            <div class="col justify-content-center">
              <div  class="info-component text-center align-middle mt-auto mb-auto">
                <div class="info-component-space">
                  <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
                </div>
                <div class="row" style="margin-top: 20px;">
                  <div class="col-lg-12">
                    <h3 class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                    <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="featuredRolePermissions">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <!-- Reason -->
              <div class="form-group">
                <label for="roleEditName">{{ $t('roles.edit.name.label') }}</label>
                <input
                    id="roleEditName"
                    v-model="featuredRole.name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.featuredRole.name.$error }"
                    v-on:input="validateRoleEdit()"
                />
                <div v-if="$v.featuredRole.name.$error" class="invalid-feedback">
                  <span v-if="!$v.featuredRole.name.required">{{ $t('roles.edit.name.required') }}</span>
                  <span v-if="!$v.featuredRole.name.minLength">{{ $t('roles.edit.name.minlength') }}</span>
                  <span v-if="!$v.featuredRole.name.maxLength">{{ $t('roles.edit.name.maxlength') }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <!-- Reason -->
              <div class="form-group">
                <label for="roleEditColor">{{ $t('roles.edit.color.label') }}</label>
                <b-form-input
                    id="roleEditColor"
                    type="color"
                    v-model="featuredRole.color"
                />
              </div>
            </div>
          </div>
          <hr>
          <div class="mb-2">
            <span class="d-inline-block">
              <h5 class="mb-1">{{ $t('roles.permissions.title') }}</h5>
              <h6 class="mb-1 text-muted">{{ $t('roles.permissions.description') }}</h6>
            </span>
            <button v-if="!featuredRole.ownership_role" class="btn btn-primary btn-sm float-right d-inline-block mt-1" @click="featuredRolePermissionSet.toggle()">
              {{ $t('roles.permissions.toggle') }}
            </button>
          </div>
            <PermissionCollapse v-if="!featuredRole.ownership_role" :permission-set="featuredRolePermissionSet"></PermissionCollapse>
            <div v-else>
                <h4 class="alert alert-warning text-center mt-2 mb-2">
                    {{ $t('roles.edit.ownership') }}
                </h4>
            </div>
          <div class="row" v-if="featuredRole.modify">
            <div class="col">
              <button class="btn btn-success btn-block" v-on:click="updateFeaturedRole()" :class="{'disabled': featuredRoleInProgress}">
                <half-circle-spinner
                    v-if="featuredRoleInProgress"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                {{ $t('roles.edit.save.button') }}
              </button>
            </div>
          </div>
          <div class="row mt-2" v-if="featuredRole.delete">
            <div class="col">
              <button class="btn btn-outline-danger btn-block" v-on:click="deleteRole()" :class="{'disabled': featuredRoleInProgress}">
                <half-circle-spinner
                    v-if="featuredRoleInProgress"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                {{ $t('roles.edit.delete.button') }}
              </button>
            </div>
          </div>
        </template>
      </b-modal>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
